import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import baseUrl from "@/utils/baseUrl.js";
// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)

// 导入全局样式
import "@/assets/css/common.css";
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
Vue.prototype.$axios = axios;
Vue.prototype.$baseUrl = baseUrl;

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
