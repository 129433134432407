var URL = {
  // socketUrl: 'wss://ws.xingyuancheng.com/', // 带看中 socket地址 线上
  // socketUrl: 'wss://sit-ws.xingyuancheng.com/', // 带看中 socket地址 测试服  （旧）
  //socketUrl: 'ws://124.70.90.123:9100/ws', // 带看中 socket地址 测试服  （新）
  // socketUrl: 'wss://entfrm-ws.xingyuancheng.com/ws', // 带看中 socket地址 测试服  （改1）
  socketUrl: 'wss://pro-ws.xingyuancheng.com/ws', // 带看中 socket地址 测试服  （线上1）
  // socketUrl: 'ws://10.23.64.141:91/ws', // 带看请求 socket地址 开发服
  // socketUrl : 'ws://10.23.65.96:8888/ws', // 带看请求 socket地址 开发服

  // requestUrl: "https://xyc.xingyuancheng.com/", // 接口请求地址
  // requestUrl: "https://sit-xyc.xingyuancheng.com/", // 接口请求地址  (旧)
 // requestUrl: "https://entfrm-xyc.xingyuancheng.com", // 接口请求  （新）
  requestUrl: "https://pro-xyc.xingyuancheng.com", // 接口请求  （线上1）
 // requestUrl:'http://47.108.76.5:9090',//ip接口（移动端在用）
  // requestUrl: "http://10.23.64.141:8080/", // 接口请求地址
  // requestUrl: "http://10.23.65.96:8080/", // 接口请求地址

  // phonUrl: 'https://pano.xingyuancheng.com/pano/', // VR场景基准路径
  phonUrl: 'https://pano.xingyuancheng.com/', // VR场景基准路径
  // phonUrl: 'https://php-vr.xingyuancheng.com/pano/', // 测试环境 - VR场景基准路径
  // phonUrl: 'https://php-vr.xingyuancheng.com/', // 测试环境 - VR场景基准路径
}

module.exports = URL